import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/layout.js";
import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const LineTextLine = makeShortcode("LineTextLine");
const Container = makeShortcode("Container");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={props.pageContext.frontmatter.title} description={props.pageContext.frontmatter.description} mdxType="Seo" />
    <LineTextLine title={props.pageContext.frontmatter.title} mdxType="LineTextLine" />
    <Container mdxType="Container">
  <section className="pb-5">
    <p>
      This cookie policy (&quot;Policy&quot;) describes what cookies are and how
      Website Operator (&quot;Website Operator&quot;, &quot;we&quot;,
      &quot;us&quot; or &quot;our&quot;) uses them on the{" "}
      <Link to="/" mdxType="Link">andreybond.com</Link> website and any of its products or
      services (collectively, &quot;Website&quot; or &quot;Services&quot;).
    </p>
    <p>
      You should read this Policy so you can understand what type of cookies we
      use, the information we collect using cookies and how that information is
      used. It also describes the choices available to you regarding accepting
      or declining the use of cookies.
    </p>
    <h4>What are cookies?</h4>
    <p>
      Cookies are small pieces of data stored in text files that are saved on
      your computer or other devices when websites are loaded in a browser. They
      are widely used to remember you and your preferences, either for a single
      visit (through a &quot;session cookie&quot;) or for multiple repeat visits
      (using a &quot;persistent cookie&quot;).
    </p>
    <p>
      Session cookies are temporary cookies that are used during the course of
      your visit to the Website, and they expire when you close the web browser.
    </p>
    <p>
      Persistent cookies are used to remember your preferences within our
      Website and remain on your desktop or mobile device even after you close
      your browser or restart your computer. They ensure a consistent and
      efficient experience for you while visiting our Website or using our
      Services.
    </p>
    <p>
      Cookies may be set by the Website (&quot;first-party cookies&quot;), or by
      third parties, such as those who serve content or provide advertising or
      analytics services on the website (&quot;third party cookies&quot;). These
      third parties can recognize you when you visit our website and also when
      you visit certain other websites.
    </p>
    <h4>What type of cookies do we use?</h4>
    <h5>Necessary cookies</h5>
    <p>
      Necessary cookies allow us to offer you the best possible experience when
      accessing and navigating through our Website and using its features. For
      example, these cookies let us recognize that you have created an account
      and have logged into that account to access the content.
    </p>
    <h5>Analytical cookies</h5>
    <p>
      These cookies enable us and third-party services to collect aggregated
      data for statistical purposes on how our visitors use the Website. These
      cookies do not contain personal information such as names and email
      addresses and are used to help us improve your user experience of the
      Website.
    </p>
    <h5>Social media cookies</h5>
    <p>
      Third-party cookies from social media sites (such as Facebook, Twitter,
      etc) let us track social network users when they visit our Website, use
      our Services or share content, by using a tagging mechanism provided by
      those social networks.
    </p>
    <p>
      These cookies are also used for event tracking and remarketing purposes.
      Any data collected with these tags will be used in accordance with our and
      social networks’ privacy policies. We will not collect or share any
      personally identifiable information from the user.
    </p>
    <h4>What are your cookie options?</h4>
    <p>
      If you don't like the idea of cookies or certain types of cookies, you can
      change your browser's settings to delete cookies that have already been
      set and to not accept new cookies. To learn more about how to do this or
      to learn more about cookies, visit{" "}
      <a target="_blank" rel="noopener noreferrer" href="https://www.internetcookies.org">
        internetcookies.org
      </a>
    </p>
    <h4>Changes and amendments</h4>
    <p>
      We reserve the right to modify this Policy relating to the Website or
      Services at any time, effective upon posting of an updated version of this
      Policy on the Website. When we do we will post a notification on the main
      page of our Website. Continued use of the Website after any such changes
      shall constitute your consent to such changes. Policy was created with{" "}
      <a style={{
            "color": "inherit"
          }} target="_blank" rel="noopener noreferrer" title="Cookie policy generator" href="https://www.websitepolicies.com/blog/sample-cookie-policy-template">
        WebsitePolicies
      </a>
      .
    </p>
    <h4>Acceptance of this policy</h4>
    <p>
      You acknowledge that you have read this Policy and agree to all its terms
      and conditions. By using the Website or its Services you agree to be bound
      by this Policy. If you do not agree to abide by the terms of this Policy,
      you are not authorized to use or access the Website and its Services.
    </p>
    <h4>Contacting us</h4>
    <p>
      If you would like to contact us to understand more about this Policy or
      wish to contact us concerning any matter relating to our use of cookies,
      you may do so via the <Link to="/about-me" mdxType="Link">contact form</Link> or send an
      email to
      a&#110;&#100;&#114;eybond.&#112;h&#111;&#116;o&#64;&#111;&#117;tl&#111;&#111;&#107;.&#99;om
    </p>
    <p>
      This document was last updated on{" "}
      <time dateTime="2020-04-23 20:00">April 23, 2020</time>
    </p>
  </section>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      